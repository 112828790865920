<template>
  <v-card class="listcard elevation-1">
    <v-card-text>
      <h6 class="text-uppercase font-weight-black">
        {{ $tc('invoices', 2) }}
      </h6>
      <table style="width: 100%">
        <thead>
          <tr class="text-left">
            <th>#</th>
            <th>{{ $tc('customer', 1) }}</th>
            <th>{{ $t('invoice') }}</th>
            <th>{{ $t('balance') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in invoices" :key="index">
            <td style="width: 12%">
              {{ item.invoice_number }}
            </td>
            <td style="width: 38%">
              {{ item.extraInformation.customer }}
            </td>
            <td style="width: 25%">
              <i-money
                v-if="item.total !== null"
                :value="item.total"
                mode="text"
              >
              </i-money>
            </td>
            <td style="width: 25%"></td>
            <td style="width: 25%">
              <i-money
                v-if="item.balance !== null"
                :value="item.balance"
                mode="text"
              >
              </i-money>
            </td>
          </tr>
        </tbody>
      </table>
      <table style="width: 100%">
        <tbody style="height: 12em; overflow-y: auto">
          <tr>
            <td style="width: 12%"></td>
            <td style="width: 38%"></td>
            <td style="width: 25%">
              <i-money class="font-weight-bold" :value="total" mode="text">
              </i-money>
            </td>
            <td style="width: 25%"></td>
            <td style="width: 25%">
              <i-money class="font-weight-bold" :value="balance" mode="text">
              </i-money>
            </td>
          </tr>
        </tbody>
      </table>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  data() {
    return {
      invoices: [],
      loading: false
    }
  },
  mounted() {
    this.getInvoice()
  },
  computed: {
    balance() {
      return this.invoices
        .reduce(
          (accumulator, currentValue) =>
            accumulator + Number(currentValue.total),
          0
        )
        .toFixed(2)
    },
    total() {
      return this.invoices
        .reduce(
          (accumulator, currentValue) =>
            accumulator + Number(currentValue.balance),
          0
        )
        .toFixed(2)
    }
  },
  methods: {
    async getInvoice() {
      this.loading = true
      const response = await this.$api.invoice.list({
        //opt: { params: { status: true } }
      })
      this.invoices = response.data.results
    }
  }
}
</script>
