<template>
  <v-card class="listcard elevation-1 h-100">
    <v-card-text>
      <h6 class="text-uppercase font-weight-black">
        {{ $tc('loginlogs', 2) }}
      </h6>
      <v-list
        style="
          max-height: 12em;
          overflow-y: auto;
          background-color: transparent;
        "
      >
        <template v-for="(item, index) in logs">
          <div :key="index">
            <span class="font-weight-black">
              {{ item.object_repr }}
            </span>
            {{ action_flag[item.action] }} /
            {{ getDatestamp(item.timestamp) }}
          </div>
        </template>
      </v-list>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  data() {
    return {
      logs: [],
      action_flag: {
        1: this.$t('added'),
        2: this.$t('updated'),
        3: this.$t('deleted'),
        4: this.$t('login'),
        5: this.$t('logout'),
        6: this.$t('login_failed')
      }
    }
  },
  mounted() {
    this.getLogs()
  },
  methods: {
    getDatestamp(date) {
      let d = new Date(date)
      let format = `${d.getFullYear()}-${('0' + (d.getMonth() + 1)).slice(
        -2
      )}-${('0' + d.getDate()).slice(-2)}`
      return `${this.dateFormat(format)} ${d.toLocaleTimeString()}`
    },
    async getLogs() {
      const response = await this.$api.dashboard.logs({
        opt: {
          params: {
            login: true
          }
        }
      })
      this.logs = response.data
    }
  }
}
</script>
