<route>
{
  "meta": {
    "permission": [
      "auth.view_user",
      "product.view_product",
      "customer.view_customer",
      "sales.view_quotation",
      "invoices.view_invoices"
    ]
  }
}
</route>

<template>
  <v-container fluid main-container>
    <v-row class="row-gap">
      <v-col cols="12" sm="6" md="3" class="graph pt-0 px-1 pb-3">
        <CustomerCard />
      </v-col>
      <v-col cols="12" sm="6" md="3" class="graph pt-0 px-1 pb-3">
        <ProductCard />
      </v-col>
      <v-col cols="12" sm="6" md="3" class="graph pt-0 px-1 pb-3">
        <UserCard />
      </v-col>
      <v-col cols="12" sm="6" md="3" class="graph pt-0 px-1 pb-3">
        <QuotationCard />
      </v-col>

      <v-col cols="12" md="6" class="pt-0 px-1 pb-3">
        <LoginCard />
      </v-col>
      <v-col cols="12" md="6" class="pt-0 px-1 pb-3">
        <InvoiceCard />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import CustomerCard from '../../components/overview/CustomerCard'
import ProductCard from '../../components/overview/ProductCard'
import UserCard from '../../components/overview/UserCard'
import QuotationCard from '../../components/overview/QuotationCard'
import LoginCard from '../../components/overview/LoginCard'
import InvoiceCard from '../../components/overview/InvoiceCard'

export default {
  components: {
    ProductCard,
    UserCard,
    CustomerCard,
    QuotationCard,
    LoginCard,
    InvoiceCard
  },
  mounted() {
    if (this.$store.getters.getIsloading) {
      this.$store.dispatch('actionLoading')
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  @import './overview.scss';
}

.container.main-container {
  .row-gap {
    display: flex;
    width: 100%;
    flex: 1 1 auto;
    margin: 0;
    padding: 20px 0;
  }
}
.listcard {
  cursor: default;
}
</style>
