<template>
  <v-card class="listcard elevation-1">
    <v-card-text class="pa-0 d-flex fill-height">
      <v-row no-gutters>
        <v-col cols="5" class="d-flex flex-wrap pl-1 py-1">
          <v-col
            cols="12"
            class="text-left text-uppercase font-weight-black pa-0"
          >
            {{ $tc('order', 2) }}
          </v-col>
          <v-col
            :class="{
              'text-truncate': true,
              'font-weight-black': true,
              'text-center': true,
              'text-uppercase': true,
              caption: $vuetify.breakpoint.smAndDown
            }"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <h1 v-on="on">{{ quotation.total }}</h1>
              </template>
              <span>{{ $tc('total', 1) }}</span>
            </v-tooltip>
          </v-col>
        </v-col>
        <v-col
          cols="7"
          :class="{
            'd-flex justify-center align-center pt-6': true,
            'text-truncate': false,
            'font-weight-black': true,
            'text-center': true,
            'text-uppercase': true,
            headline: true,
            caption: $vuetify.breakpoint.smAndDown
          }"
        >
          <i-money
            :class="{
              'primary--text': true,
              'text-uppercase': true,
              'font-weight-black': true
            }"
            v-if="quotation.amount !== null"
            :value="quotation.amount"
            mode="text"
          >
          </i-money>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  data() {
    return {
      quotation: {
        total: 0,
        amount: 0
      }
    }
  },
  methods: {
    async getQuotations() {
      const response = await this.$api.dashboard.overview({
        type: 'quotations_count'
      })
      this.quotation = response.data
    }
  },
  mounted() {
    this.getQuotations()
  }
}
</script>
