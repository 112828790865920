<template>
  <v-card class="listcard elevation-1">
    <v-card-text class="pa-0">
      <v-row no-gutters>
        <v-col cols="5" class="d-flex flex-wrap pl-1 py-1">
          <v-col
            cols="12"
            class="text-left text-uppercase font-weight-black pl-1 py-1"
          >
            {{ $tc('customer', 2) }}
          </v-col>
          <v-col
            :class="{
              'text-truncate': true,
              'font-weight-black': true,
              'text-center': true,
              'text-uppercase': true,
              caption: $vuetify.breakpoint.smAndDown
            }"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <h1 v-on="on">{{ customer && customer.total }}</h1>
              </template>
              <span>{{ $tc('total', 1) }}</span>
            </v-tooltip>
          </v-col>
        </v-col>
        <v-col
          cols="7"
          :class="{
            'text-truncate': true,
            'font-weight-black': true,
            'text-center': true,
            'text-uppercase': true,
            caption: $vuetify.breakpoint.smAndDown
          }"
        >
          <pie-chart :chart-data="customerData" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import PieChart from './PieChart'

export default {
  components: {
    PieChart
  },
  data() {
    return {
      customerData: null,
      customer: null
    }
  },
  methods: {
    async getCustomers() {
      const response = await this.$api.dashboard.overview({
        type: 'customer_count'
      })
      this.customer = response.data
      this.customerData = {
        labels: [this.$tc('active', 1), this.$tc('inactive', 1)],
        datasets: [
          {
            backgroundColor: ['#00D8FF', '#E46651'],
            data: [this.customer.active, this.customer.inactive]
          }
        ]
      }
    }
  },
  mounted() {
    this.getCustomers()
  }
}
</script>
